import React from 'react';
import Layout from '../../components/layout';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ContentCopy from '../../components/content-copy';
import ContentImageXL from '../../components/content-image-xl';
import ContentImageL from '../../components/content-image-l';
import ContentImageM from '../../components/content-image-m';


const VolantisDS = ({ data }) => (
    <Layout>
        <section className="columns is-centered case-studies is-gapless is-multiline">
            <div className="column is-12">
                <Img
                    className="featured-image"
                    fluid={data.imageOne.childImageSharp.fluid}
                    alt="Featured Image"
                />
            </div>
            <div className="column is-12 padding-bottom-0 padding-top-0">
                <div className="columns is-centered">
                    <div className="column is-5 is-offset-1">
                        <div className="page-title-wrap margin-bot-3">
                            <span className="title-decoratiion"></span>
                            <h1 className="general-page-title">HRIS MarkPlus, Inc.</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column is-5 padding-top-0">
                <div className="table-wrapper">
                    <table className="table is-fullwidth">
                        <tbody>
                            <tr>
                                <td><h2>Client</h2></td>
                                <td><p>MarkPlus, Inc.</p></td>
                            </tr>
                            <tr>
                                <td><h2>Overview</h2></td>
                                <td>
                                    <p>HRIS is the abbreviation of a human resource information system, in this case, it is a system that tailored for MarkPlus’s needs. Initially there was an prior system that used for years built by one of our vendor. Unfortunately, this system was a failure, it can be seen that the system didn't meet the company’s requirements and the majority of employees didn’t use it.</p><br />

                                    <p>Moreover, the experience was horrible which can be seen that employees complaining about it because it is hard to understand and not sure is it works or not, they tend to use back and fort email works instead. Not to mention, our HR was also having a hard time to introduce the system to the newcomer due to its poorly designed app. Finally, after years of the clutter of rules and track records our VP assigned HRIS redesign project to our team with some request about new features.</p>
                                </td>
                            </tr>
                            <tr>
                                <td><h2>Outcome</h2></td>
                                <td>
                                    <p>After the Beta testing revision is done, we fully migrate to the new system and some introduction sessions to our employees. We are excited to be pulling in data and using it to make more informed design decisions.</p>
                                    <br />
                                    <p>The majority of employees now being actively checking the system to monitor their attendance performance, they are no more excuses to not make a leave request, according to the HR number of issues related to leaving and attendance drastically decreased, their job now getting easier.</p>
                                    <br />
                                    <p>Eventually, due to some reasons, I didn't have the chance to gather qualitative results, because I was transferred to another project. However, this project was marked as a successful project by the stakeholders and even better, actively used by employees that previously refused to use the previous HR system.</p>
                                </td>
                            </tr>
                            <tr>
                                <td><h2>My Role</h2></td>
                                <td><p>User research, Visual design, Prototyping, Front-end</p></td>
                            </tr>
                            <tr>
                                <td><h2>Tools</h2></td>
                                <td><p>Adobe XD, HTML, CSS/SASS</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="columns is-centered padding-top-0 is-multiline">
                <div className="column is-12">
                    <ContentImageXL>
                        <Img
                            fluid={data.imageTwo.childImageSharp.fluid}
                            alt="screen samples"
                        />
                    </ContentImageXL>
                    <ContentCopy>
                        <h2>Research</h2>
                        <h3>The Brief</h3>
                        <p>The existing system was hard to use and no longer able to accommodate our needs, at this moment, issues related to HR departments such as informal leave requests, allowance calculation, and attendance monitoring are getting worse. Stakeholder wanted to add some new features with flexible adjustments on the go.</p>

                        <h3>User Research</h3>
                        <p>Couple workshops and interviews were conducted with the stakeholders, IT officers, and employees. My research encompassed:</p>
                        <ul>
                            <li>Understanding the goals and pain points</li>
                            <li>Validate current and new features</li>
                            <li>Understand the technical constraints and data privacy policy</li>
                            <li>Persona development</li>
                        </ul>
                        <p>I conducted interviews with 13 employees from various divisions and job levels ranging from staff level until the director level. I stopped these interviews after the 13th person because I was not getting new findings. At that moment I didn't know that 5 participants are the golden number.</p>

                        <h3>Insights</h3>
                        <ol>
                            <li>Information architecture issues</li>
                            <li>Nonstandard pattern/interaction</li>
                            <li>Not mobile-friendly</li>
                            <li>Existing generic software force the user to use unnecessary steps/features</li>
                            <li>No reminder and notifications (by email or within the app)</li>
                            <li>Can only be accessed using the office internet</li>
                            <li>Loose application regulation</li>
                            <li>There is no data disclosure so when there is an error the employee cannot show any evidence</li>
                        </ol>

                        <h3>User Profiles</h3>
                        <p>The research made it evident how different users would use the app differently in certain features namely, leave and attendance. To cater to this, I categorized them into three user profiles based on their goals and tasks.</p>

                        <h4>1. Manager</h4>
                        <p>High mobility | prefer mobile app Uses Whatsapp, Spreadsheet, Gmail Task Performed: Request Leave, approve leave request, check personal attendance, monitor team’s attendance, report error data attendance.</p>
                        <h4>2. Staff</h4>
                        <p>Occationally travel around | prefer responsive web app Uses Whatsapp, Spreadsheet, Gmail, Instagram Task Performed: Request leave, check personal attendance, report error data attendance.</p>
                        <h4>3. Manager</h4>
                        <p>Mostly stay at the office | willing to use mobile and web app Uses Whatsapp, Spreadsheet, Gmail, Instagram Task Performed: Request leave, approve leave, check personal attendance, check employees’s attendance, update attendance data.</p>


                        <h3>Competitive Analysis</h3>
                        <p>I compared the most relevant HR platform out there whether it is web-based, mobile app, or both to take a good technique or approach that can be adopted and prevent making mistakes that have been overcome by similar products (Zenefits, Sleekr, Jojonomic).</p>
                    </ContentCopy>

                    <ContentImageL>
                        <Img
                            fluid={data.imageFive.childImageSharp.fluid}
                            alt="competitors"
                        />
                    </ContentImageL>

                    <ContentCopy>
                        <h3>Why web app & mobile app?</h3>

                        <p>After conducting the research we realize that we have to develop a web app and mobile app to accommodate our user's diverse behavior. Some of them are quite active use the features while some only use it twice a month or so.</p>
                        <br />
                        <p>Furthermore, Due to delayed mobile developer hiring process and some other release urgency our team decided to develop the web app version first. Afterward, we were hoping after we release the web version we can get some insights for our mobile app version and the next iteration as a whole. In addition, I used the mobile-first design approach in these projects.</p>
                        <br />
                        <p>To make sure the way we structured this app is intuitive yet familiar (compared to the previous platform) I did grouping and sorting sessions with the HR team and some random employees.</p>
                    </ContentCopy>
                    <ContentImageM>
                        <Img
                            fluid={data.imageSix.childImageSharp.fluid}
                            alt="card sorting"
                        />
                    </ContentImageM>
                    <ContentCopy>
                        <p>Moving on, I explore some possible approaches using pen and papers before finally made up digital wireframes version to be get tested. I was using Axure RP back then. Here are some initial wireframes for the web app version I used to validate my concept within dev team and stakeholders.</p>
                    </ContentCopy>
                    <ContentImageM>
                        <Img
                            fluid={data.imageSeven.childImageSharp.fluid}
                            alt="Wireframe 1"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.imageEight.childImageSharp.fluid}
                            alt="Wireframe 2"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.imageNine.childImageSharp.fluid}
                            alt="Wireframe 3"
                        />
                    </ContentImageM>
                    <ContentCopy>
                        <h2>Visual Exploration</h2>
                        <p>After some iterations, I moved to the visual stage that covers color, typography, icon, and spacing. Since there are android and ios platform as well as a web app it is quite challenging to cater all the rules/guides. Not to mention, I have to avoid making drastic redesign from previous temporary web app solution.</p>
                    </ContentCopy>
                    <ContentImageM>
                        <Img
                            fluid={data.imageTen.childImageSharp.fluid}
                            alt="mood board"
                        />
                    </ContentImageM>
                    <ContentCopy>
                        <h2>The Web App</h2>
                        <h3>Jump to the slicing process</h3>
                        <p>As expected, there was tech constraints and some privacy issues that forced me to postponed or even worse throw some features. So after the revision was noted, in order to catch the timeline I did the revision in the form of HTML format so that our developer can start coding parallelly.</p>
                    </ContentCopy>
                    <ContentImageM>
                        <Img
                            fluid={data.imageEleven.childImageSharp.fluid}
                            alt="responsive sample"
                        />
                    </ContentImageM>
                    <ContentCopy>
                        <p>Noticeably, this time there are no single employees involved due to the timeline and limited support from authority issues. Therefore, we have to save our only chance to conduct proper usability testing with real users one time only. Our team decided to do that in production, with a hope that we will able to do the test case (developer side) parallel with the usability testing. Since I was the only one who took the design and front-end responsibility I did both works at a tight deadline. It was a bit frustrating to tackle everything once while learning new things at the same time. Sometimes some unexpected revisions regarding regulations within the flows happen in the development times.</p>
                    </ContentCopy>
                    <ContentImageM>
                        <Img
                            fluid={data.imageTwelve.childImageSharp.fluid}
                            alt="large screen sample"
                        />
                    </ContentImageM>
                    <ContentCopy>
                        <h2>The Mobile App</h2>
                        <p>The mobile app version has its own challenges but thankfully this time I was able to design the high fidelity with a decent timeline. I discovered some new improvements that can be done from the previous iteration and I have to consider both ios and android design language. The app was developed using React Native.</p>
                        <br />
                        <p>Furthermore, after some discussions with our graphic design team, I decided to update the look and feel a bit. I realized that this would cause design inconsistency within the redesigned timeline but we decided to go that way since the web version got some critics about its look and feel.</p>
                    </ContentCopy>
                    <ContentImageM>
                        <Img
                            fluid={data.imageThirteen.childImageSharp.fluid}
                            alt="mobile app sample"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.imageFourteen.childImageSharp.fluid}
                            alt="mobile app sample 2"
                        />
                    </ContentImageM>
                    <ContentCopy>
                        <h2>Validate</h2>
                        <h3>Usability Testing</h3>
                        <p>Thankfully, That took 2 days only, those usability sessions are priceless to me, since those are the days that I’m able to successfully conducted the session with a lot of strategic level people involved by my self as a junior and self-taught designer (in a corporate company that resistant to design thinking approach).</p>
                    </ContentCopy>
                    <ContentImageM>
                        <Img
                            fluid={data.imageFifteen.childImageSharp.fluid}
                            alt="usability testing plan"
                        />
                    </ContentImageM>
                    <ContentCopy>
                        <p>The result was surprisingly smoother than we thought, we only have to do some minor changes in visual matters such as color contrast, font size, button placements. Even further, participants were enthusiastic to give advice about a nice to have features to be considered for the next iteration. However, we have to admit that there were bugs here and there since this is an early beta-test as well.</p>

                        <h2>Impact</h2>
                        <h3>The good and bad</h3>
                        <p>After the Beta testing revision is done, we fully migrate to the new system and some introduction sessions to our employees. We are excited to be pulling in data and using it to make more informed design decisions.</p>
                        <br />
                        <p>The majority of employees now being actively checking the system to monitor their attendance performance, they are no more excuses to not make a leave request, according to the HR number of issues related to leaving and attendance drastically decreased, their job now getting easier.</p>
                        <br />
                        <p>Eventually, due to some reasons, I didn't have the chance to gather qualitative results, because I was transferred to another project. However, this project was marked as a successful project by the stakeholders and even better, actively used by employees that previously refused to use the previous HR system.</p>

                        <h2>Project Learnings</h2>
                        <h4>1. Get the buy-in</h4>
                        <p>As a designer, we have to get the buy-in from stakeholders and everyone on the team that involved, not getting everyone on the same page right from the start will cause some serious issues that will change the direction of the product and doing something useless or unnecessary.</p>

                        <h4>2. Prioritize</h4>
                        <p>Create a strategic plan to launch an MVP. This helps deal with out-of-scope requests that could potentially derail the project and help deliver a quality product in time.</p>

                        <h4>3. Seek out for feedback as fast as possible</h4>
                        <p>The trouble with most of us is that we would rather be ruined by praise than saved by criticism. Keeping the stakeholders/users in loop and testing solutions in whatever form (paper, low-fi or hi-fi) as early as possible saves ample amount of time and re-work.</p>

                        <h4>4. Design handoff</h4>
                        <p>Since this is my first time lead a big revamp project that implements design thinking and deal with UX processes while learning it on the go everything fell rushed and I have to admit that my handoff to our fellow developers was not that good, that time I didn't know about something like Zeplin. That caused guessing games that ruin the padding size, color, font-size, etc.</p>
                    </ContentCopy>
                </div>
            </div>
            <div className="column is-12">
                <hr />
            </div>

            {/* More Cases Section  */}
            <div className="column is-10">
                <div className="columns is-centered is-multiline">
                    <div className="column is-12">
                        <h3 className="has-text-centered margin-top-0">More Case Studies</h3>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/gredu-design-system-v0.1">
                                        <Img fluid={data.imageSixteen.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/gredu-design-system-v0.1"><h2 className="read-more-title">Gredu Design System V.01</h2></Link>
                                        <Link to="/case-studies/gredu-design-system-v0.1"><p className="read-more-excerp">A design system starter that will cover more than 5 products in the school ecosystem (web, iOS, & Android).</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/student-app-course">
                                        <Img fluid={data.imageFour.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/student-app-course"><h2 className="read-more-title">Student App - Course</h2></Link>
                                        <Link to="/case-studies/student-app-course"><p>Adding a course platform with a subscription model into an existing student app.</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/volantis-design-system">
                                        <Img fluid={data.imageSeventeen.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/volantis-design-system"><h2 className="read-more-title">Volantis Design System</h2></Link>
                                        <Link to="/case-studies/volantis-design-system"><p className="read-more-excerp">The design language that speaks for B2B startup with a modern approach. Covering foundation, UI Kit (for designer & engineer), design assets central.</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout >
);

export default VolantisDS;


export const query = graphql`
  query hrisMarkplusQuery {
    imageOne:file(relativePath: { eq: "hris-markplus-hero.png" }) {
      childImageSharp {
        fluid(maxWidth:2000, quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo:file(relativePath: { eq: "hris-markplus-mobile-samples.png" }) {
        childImageSharp {
          fluid(maxWidth:2000, quality:80) {
            ...GatsbyImageSharpFluid
          }
        }
    }
    imageFour:file(relativePath: { eq: "student-app-course-page.png" }) {
        childImageSharp{
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageFive:file(relativePath: { eq: "hris-markplus-competitors.png" }) {
        childImageSharp{
            fluid(quality:90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageSix:file(relativePath: { eq: "hris-markplus-card-sorting.jpg" }) {
        childImageSharp{
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageSeven:file(relativePath: { eq: "hris-markplus-wireframe-1.png" }) {
        childImageSharp{
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageEight:file(relativePath: { eq: "hris-markplus-wireframe-2.png" }) {
        childImageSharp{
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageNine:file(relativePath: { eq: "hris-markplus-wireframe-3.png" }) {
        childImageSharp{
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageTen:file(relativePath: { eq: "hris-markplus-mood-board.png" }) {
        childImageSharp{
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageEleven:file(relativePath: { eq: "hris-markplus-responsive-design-sample.png" }) {
        childImageSharp{
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageTwelve:file(relativePath: { eq: "hris-markplus-large-screen.png" }) {
        childImageSharp{
            fluid(maxWidth: 2000, quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageThirteen:file(relativePath: { eq: "hris-markplus-mobile-row-1.png" }) {
        childImageSharp{
            fluid(maxWidth: 2000){
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageFourteen:file(relativePath: { eq: "hris-markplus-mobile-row-2.png" }) {
        childImageSharp{
            fluid(maxWidth: 2000){
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageFifteen:file(relativePath: { eq: "hris-markplus-usability-test-plan.png" }) {
        childImageSharp{
            fluid(maxWidth: 2000, quality: 100){
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageSixteen:file(relativePath: { eq: "gredu-ds-page.png" }) {
        childImageSharp{
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageSeventeen:file(relativePath: { eq: "volantis-ds-page.png" }) {
        childImageSharp{
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }
    

  }
`
